/* eslint-disable max-len */
import React, { memo, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { EnvironmentOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Divider, Menu, Row } from 'antd';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import socials from '~/constants/socials';
import AppleStoreSvg from '~/assets/apple-store.svg';
import googlePlayImg from '~/assets/google-play.png';
import PromoteAppButton from './PromoteAppButton';
import styles from './PublicFooter.module.scss';

const partnerSites = [
    {
        text: 'Bondi Beach',
        url: '//www.bondibeach.com.au',
    },
    {
        text: 'Cabramatta',
        url: '//cabramatta.com.au',
    },
    {
        text: 'Erina',
        url: 'http://www.erina.com.au/',
    },
    {
        text: 'Ettalong',
        url: 'http://www.ettalongbeach.com.au',
    },
    {
        text: 'Gosford',
        url: '//www.gosford.com.au',
    },
    {
        text: 'Manly',
        url: '//manly.com.au',
    },
    {
        text: 'Port Macquarie',
        url: '//www.portmacquarie.com.au',
    },
    {
        text: 'Sawtell',
        url: '//sawtell.com.au',
    },
    {
        text: 'Terrigal',
        url: '//www.terrigal.com.au',
    },
    {
        text: 'Tuggerah',
        url: '//www.tuggerah.com.au',
    },
    {
        text: 'Tweed Heads',
        url: '//tweedheads.com.au',
    },
    {
        text: 'Wyong',
        url: '//wyong.com.au/',
    },
];

const menu = (
    <Menu>
        {partnerSites.map(({ text, url }) => (
            <Menu.Item key={url}>
                <a target="_blank" alt={text} rel="noopener noreferrer" href={url}>
                    {text}
                </a>
            </Menu.Item>
        ))}
    </Menu>
);

const PublicFooter = memo(() => {
    const { pathname } = useRouter();
    const [isShow, setIsShow] = useState(true);

    useEffect(() => {
        const isShowAppPromote = pathname !== '/property/[slug]';
        setIsShow(isShowAppPromote);
    }, [pathname]);

    return (
        <div className={`${styles.PublicFooter} relative z-10 text-gray-500`}>
            <Row justify="center" className="py-8">
                <Col xs={24} sm={12} md={5} lg={5} xl={5} className="relative z-10 mb-4 p-4 md:p-0">
                    <p className="mb-6 text-center text-xl font-normal text-white md:text-left md:text-2xl md:font-light">
                        About Us
                    </p>
                    <p className="text-center text-xl font-semibold text-white md:text-left md:text-2xl">
                        Connecting you &amp; property differently.
                    </p>
                </Col>
                <Col xs={24} sm={12} md={7} lg={5} xl={4} className="relative z-10 mb-4">
                    <p className="mb-6  text-center text-xl font-normal text-gray-200 md:text-left md:text-2xl md:font-light">
                        Contact Us
                    </p>

                    <div className="flex justify-center md:justify-start">
                        <ul className="text-white">
                            <li className="flex justify-start">
                                <EnvironmentOutlined className="pr-2 text-3xl" />
                                <a
                                    className="text-white"
                                    href="//goo.gl/maps/kKhtBeu3FMR2"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    The Element Building
                                    <br />
                                    Level 4
                                    <br />
                                    200 Central Coast Highway
                                    <br />
                                    Erina NSW 2250
                                </a>
                            </li>

                            <li className="flex items-center justify-start pt-4">
                                <PhoneOutlined className="pr-2 text-3xl" />
                                <a className="text-white" href="tel:1800001212">
                                    (AU) &nbsp; 1800 001 212
                                </a>
                            </li>

                            <li className="flex items-center justify-start pt-4">
                                <MailOutlined className="pr-2 text-3xl" />
                                <a className="text-white" href="mailto:support@realty.com.au">
                                    support@realty.com.au
                                </a>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={7} lg={4} xl={3} className="p-4 md:p-0">
                    <p className="mb-6  text-center text-xl font-normal text-gray-200 md:text-left md:text-2xl md:font-light">
                        For Agents
                    </p>
                    <div className="flex justify-center md:justify-start">
                        <ul className="text-white">
                            <li className="flex justify-start">
                                <Link href="/login" prefetch={false}>
                                    <a className="text-white">Log In</a>
                                </Link>
                            </li>
                            <li className="pt-4">
                                <Link href="/agency-sign-up" prefetch={false}>
                                    <a className="text-white">Agency Sign up</a>
                                </Link>
                            </li>
                            <li className="pt-4">
                                <a
                                    href="https://realtyads.io"
                                    className="text-white"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Advertising
                                </a>
                            </li>
                            <li className="pt-4">
                                <a
                                    className="text-white"
                                    href="https://channels.realty.com.au"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Channels
                                </a>
                            </li>

                            <li className="pt-4">
                                <Link href="/how-to" prefetch={false}>
                                    <a className="text-white" target="_blank">
                                        How To Videos
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={7} lg={4} xl={4} className="p-4 md:p-0">
                    <p className="mb-6  text-center text-xl font-normal text-gray-200 md:text-left md:text-2xl md:font-light">
                        Navigation
                    </p>
                    <div className="flex justify-center md:justify-start">
                        <div className="flex items-start text-white">
                            <ul className="mr-4">
                                {/*<li>*/}
                                {/*    <Link href="/faq" prefetch={false}>*/}
                                {/*        <a className="text-white" target="_blank">*/}
                                {/*            FAQ*/}
                                {/*        </a>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className="pt-4">
                                    <Link href="/suburb-profile" prefetch={false}>
                                        <a className="text-white" target="_blank">
                                            Explore Suburbs
                                        </a>
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link href="/terms-of-service" prefetch={false}>
                                        <a className="text-white" title="Terms of Service">
                                            Terms of Service
                                        </a>
                                    </Link>
                                </li>
                                {/*<li className="pt-4">*/}
                                {/*    <Link*/}
                                {/*        href="https://app.criisp.io/user/deal-rooms/e6ae0794-f3d1-417e-be4e-65543247b437/details?u=RXnlveQvj9Z3gFkMR8hYKUladaR2"*/}
                                {/*        prefetch={false}*/}
                                {/*    >*/}
                                {/*        <a className="text-white" title="Investor Relations">*/}
                                {/*            Investor Relations*/}
                                {/*        </a>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                            <ul className="mr-4">
                                <li>
                                    <Link href="/sign-up" prefetch={false}>
                                        <a className="text-white">Sign Up</a>
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link href="/privacy" prefetch={false}>
                                        <a className="text-white" title="Privacy">
                                            Privacy
                                        </a>
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link href="/about-us" prefetch={false}>
                                        <a className="text-white" title="About Us">
                                            About Us
                                        </a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3} xl={5} className="p-4 md:p-0">
                    <p className="mb-6 text-center text-xl font-normal text-gray-200 md:text-left md:text-2xl md:font-light">
                        Follow Us
                    </p>
                    <div className="social-links pb-6 text-center text-2xl md:text-left">
                        {socials.map(({ link, title, icon }) => (
                            <a key={link} href={`${link}`} title={`${title}`} target="_blank" rel="noopener noreferrer">
                                <FA icon={icon} className="mx-3 text-2xl text-gray-200 hover:text-primary-500" />
                            </a>
                        ))}
                    </div>
                    <div className="flex flex-wrap items-start justify-center md:justify-start">
                        <div className="m-2 mr-3 mt-2 xs:mr-0 xs:mt-0">
                            <a
                                href="https://apps.apple.com/us/app/realty-com-au/id1529380861#?platform=iphone"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AppleStoreSvg
                                    style={{
                                        width: 130,
                                        height: 40,
                                    }}
                                />
                            </a>
                        </div>
                        <div className="m-2 mt-2 items-center xs:mt-0">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.realtymediagroup.realtymobile"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={googlePlayImg}
                                    alt="Realty Mobile App in Google Play"
                                    layout="fixed"
                                    width={130}
                                    height={40}
                                    blurDataURL={googlePlayImg}
                                    placeholder="blur"
                                />
                            </a>
                        </div>
                    </div>
                </Col>
                <Col xs={22}>
                    <Divider className="bg-[#e2e8f0] text-white" />
                </Col>
                <Row justify="center" className="relative z-20 bg-[#64748B]">
                    <Col className="text-gray-200">
                        International Sites:{' '}
                        <a
                            href="https://www.realestate.ph"
                            className="text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Realestate Philippines"
                        >
                            The Philippines
                        </a>
                    </Col>
                </Row>
            </Row>
            <div className="relative z-30 flex w-full flex-col items-center justify-center bg-[#A2ACB9] py-3 sm:flex-row">
                {/* mr-0 sm:mr-4 */}
                <div className="mb-4 text-center text-gray-100 sm:mb-0 sm:text-left">
                    &copy; {new Date().getFullYear()} Realty.com.au. All rights reserved.
                </div>
                {/* <div className="sm:text-right text-center">
                    <Dropdown className="text-white" overlay={menu} trigger={['click', 'hover']}>
                        <Button shape="round" ghost>
                            Partners <DownOutlined />
                        </Button>
                    </Dropdown>
                </div> */}
            </div>
            <PromoteAppButton show={(visible) => setIsShow(visible)} fixed />
        </div>
    );
});
export default PublicFooter;
